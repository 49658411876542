<template>
  <form @submit.prevent="save()">
    <div class="mb-3">
      <label for="">Tiempo</label>
      <select class="custom-select custom-select-sm" v-model="time">
        <option :value="null">Posponer</option>
        <!-- <option value="0M">No posponer</option> -->
        <!-- <option value="7D">1 semana</option>
              <option value="14D">2 semanas</option>
              <option value="21D">3 semanas</option>-->
        <option value="1M">1 Mes</option>
        <option value="2M">2 Meses</option>
        <option value="3M">3 Meses</option>
        <!-- <option value="4M">4 Meses</option>
        <option value="5M">5 Meses</option>
        <option value="6M">6 Meses</option>
        <option value="7M">7 Meses</option>
        <option value="8M">8 Meses</option>
        <option value="9M">9 Meses</option>
        <option value="10M">10 Meses</option>
        <option value="11M">11 Meses</option>
        <option value="12M">12 Meses</option>
        <option value="13M">13 Meses</option>
        <option value="14M">14 Meses</option>
        <option value="15M">15 Meses</option>
        -->
      </select>
    </div>
    <div class="mb-3">
      <label for="">Razon</label>
      <app-textarea v-model="reason" maxlength="100"></app-textarea>
    </div>
    <app-button-submit></app-button-submit>
  </form>
</template>
<script>
import { DentalClinicService } from '../service';
export default {
  props: {
    //
  },
  data: () => ({
    time: null,
    reason: null,
    due: null
  }),
  methods: {
    reset() {
      this.time = null;
      this.reason = null;
      this.due = null;
    },
    loadDueData(due) {
      this.due = due;
    },
    async save() {
      var result = await DentalClinicService.putFinancingDue({
        id: this.due.id,
        exp_add_days: this.time,
        reason: this.reason
      });
      this.$emit("submitted", result);
    }
  }
};
</script>