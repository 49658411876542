<template>
  <div>
    <h4 class="text-center">FINANCIACION</h4>
    <div class="row">
      <div class="col-md-6">
        <table class="table">
          <tr>
            <th>MONTO</th>
            <td>
              <app-span-money
                :quantity="financing.amount"
                :moneyCode="financing.money_code"
              ></app-span-money>
            </td>
          </tr>
          <tr>
            <th>FECHA INICIO</th>
            <td>{{ financing.begin_date }}</td>
          </tr>
          <tr>
            <th>FECHA CREACION</th>
            <td>{{ financing.created_at }}</td>
          </tr>
          <tr>
            <th>PLAN DE TRATAMIENTO</th>
            <td>
              <router-link
                class="btn btn-light btn-sm"
                :to="
                  '/dental-clinic/patients/' +
                  financing.client_id +
                  '/treatment-plans/' +
                  financing.treatment_plan_id
                "
                ><i class="fas fa-link"></i> <span>Ver tratamientos</span>&nbsp;
                <span>{{ financing.treatment_plan_id }}</span>
              </router-link>
            </td>
          </tr>
          <tr v-show="financing.readonly">
            <th>ESTADO</th>
            <td class="text-danger">SUSPENDIDO</td>
          </tr>
        </table>
      </div>
    </div>

    <button
      class="btn btn-light mb-2 shadow-sm"
      v-show="!financing.readonly"
      @click="$refs.dSus.show()"
    >
      Suspender Financiacion
    </button>

    <table class="table">
      <thead>
        <tr>
          <th>CUOTA</th>
          <th>FECHA</th>
          <th>MONTO</th>
          <th>MONTO PAGADO</th>
          <th>ESTADO</th>
          <th>TIEMPO POSPUESTO</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="l in details" :key="l.id">
          <td>{{ ordenCuota(l.due) }}</td>
          <td>{{ l.expiration | dateFormat }}</td>
          <td>
            <app-span-input
              :value="l.amount"
              @input="changeAmount(l, $event)"
              :sm="true"
              :disabled="l.due > 0"
            >
              <app-span-money
                :quantity="l.amount"
                :moneyCode="financing.money_code"
              ></app-span-money>
            </app-span-input>
          </td>
          <td>
            <app-span-money
              :quantity="l.amount_paid"
              :moneyCode="financing.money_code"
            ></app-span-money>
          </td>
          <td>
            <span v-show="l.amount <= l.amount_paid" class="text-success"
              >PAGADO</span
            >
            <span
              v-show="l.amount > l.amount_paid && isOutDate(l.expiration)"
              class="text-danger"
            >
              DEUDA
            </span>
          </td>
          <td>
            <select
              class="custom-select custom-select-sm"
              disabled
              v-model="l.exp_add_days"
            >
              <!-- @change="changeDateDue(l, $event.target.value)" -->
              <option :value="null">Posponer</option>
              <option value="0M">No posponer</option>
              <!-- <option value="7D">1 semana</option>
              <option value="14D">2 semanas</option>
              <option value="21D">3 semanas</option>-->
              <option value="1M">1 Mes</option>
              <option value="2M">2 Meses</option>
              <option value="3M">3 Meses</option>
              <option value="4M">4 Meses</option>
              <option value="5M">5 Meses</option>
              <option value="6M">6 Meses</option>
              <option value="7M">7 Meses</option>
              <option value="8M">8 Meses</option>
              <option value="9M">9 Meses</option>
              <option value="10M">10 Meses</option>
              <option value="11M">11 Meses</option>
              <option value="12M">12 Meses</option>
              <option value="13M">13 Meses</option>
              <option value="14M">14 Meses</option>
              <option value="15M">15 Meses</option>
            </select>
          </td>
          <td>
            <button
              @click="
                $refs.elModalReprogram.show();
                $refs.elFormReprogram.reset();
                $refs.elFormReprogram.loadDueData(l);
              "
              class="btn btn-light btn-sm"
              v-show="l.amount > l.amount_paid"
            >
              <i class="fas fa-clock"></i> Posponer
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <app-modal-basic ref="elModalReprogram">
      <FormReprogram
        ref="elFormReprogram"
        @submitted="
          getList();
          $refs.elModalReprogram.hide();
        "
      ></FormReprogram>
    </app-modal-basic>

    <app-modal-yn ref="dSus" @no="$refs.dSus.hide()" @yes="suspend()">
      <p>
        <b>Suspender la financiacion?</b>
      </p>
      <table class="table">
        <tbody>
          <tr
            v-for="l in details.filter((x) => x.amount_paid == 0)"
            :key="l.id"
          >
            <td>
              <app-checkbox v-model="l.to_sus" placeholder>
                {{ ordenCuota(l.due) }}
              </app-checkbox>
            </td>
            <td>{{ l.expiration }}</td>
            <td>
              <app-span-input
                :value="l.amount"
                @input="changeAmount(l, $event)"
                :sm="true"
                :disabled="l.due > 0"
              >
                <app-span-money
                  :quantity="l.amount"
                  :moneyCode="financing.money_code"
                ></app-span-money>
              </app-span-input>
            </td>
            <td>
              <app-span-money
                :quantity="l.amount_paid"
                :moneyCode="financing.money_code"
              ></app-span-money>
            </td>
          </tr>
        </tbody>
      </table>

      <p>Las cuotas sin marcar seran eliminadas</p>
    </app-modal-yn>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import { ordenCuota } from "./utils";
import { myMoment } from "../../utils/myMoment";
import FormReprogram from "./FormReprogram.vue";
export default {
  components: {
    FormReprogram
  },
  props: {
    id: {}
  },
  data: () => ({
    financing: {},
    details: []
  }),
  created() {
    this.getList();
  },
  methods: {
    suspend() {
      DentalClinicService.putFinancing({
        suspend: 1,
        id: this.id,
        list: this.details.filter((x) => x.to_sus).map((x) => x.id)
      }).then(() => {
        this.$refs.dSus.hide();
        this.getList();
      });
    },
    changeDateDue(l, days) {
      l.exp_add_days = days;
      DentalClinicService.putFinancingDue({
        id: l.id,
        exp_add_days: days
        // expiration: time.format('YYYY-MM-DD')
      }).then(() => this.getList());
    },
    changeAmount(l, amount) {
      l.amount = amount;
      DentalClinicService.putFinancingDue({
        id: l.id,
        change_amount: 1,
        amount: amount
      }).then(() => this.getList());
    },
    ordenCuota,
    getList() {
      DentalClinicService.getFinancing(this.id).then((res) => {
        this.financing = res.financing;
        this.details = res.details.map((x) => ({
          ...x
          // expiration: myMoment(x.expiration).format("DD MMMM YYYY")
        }));
      });
    },
    isOutDate(expiration) {
      return myMoment().isAfter(expiration);
    }
  }
};
</script>

<style></style>
